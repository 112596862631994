import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LoginCallback = () => {
  const { handleRedirectCallback } = useAuth0();

  React.useEffect(() => {
    handleRedirectCallback();
  }, [handleRedirectCallback]);

  return <div>Loading...</div>;
};

export default LoginCallback;