import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LogoutButton.css';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button onClick={() => logout({ returnTo: window.location.origin })} className="nav-link logout-button">
      <i className="fas fa-sign-out-alt"></i>
      <span>Logout</span>
    </button>
  );
};

export default LogoutButton;