// BrandContext.js
import React, { createContext, useState, useCallback } from 'react';

export const BrandContext = createContext();

export const BrandProvider = ({ children }) => {
  const [selectedBrand, setSelectedBrandState] = useState(() => {
    const storedBrand = localStorage.getItem('selectedBrand');
    try {
      return storedBrand ? JSON.parse(storedBrand) : null;
    } catch (error) {
      console.error('Error parsing stored brand:', error);
      return null;
    }
  });

  const setSelectedBrand = useCallback(
    (value, callback) => {
      localStorage.setItem('selectedBrand', JSON.stringify(value));
      setSelectedBrandState(value);
      if (callback) callback();
    },
    [setSelectedBrandState]
  );

  return (
    <BrandContext.Provider value={{ selectedBrand, setSelectedBrand }}>
      {children}
    </BrandContext.Provider>
  );
};