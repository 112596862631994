import React, { useState } from 'react';
import Modal from 'react-modal';
import './NewBrandModal.css';

const NewBrandModal = ({ isOpen, onClose, onSubmit }) => {
  const [sponsor, setSponsor] = useState('');
  const [brandName, setBrandName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(sponsor, brandName);
    setSponsor('');
    setBrandName('');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Create New Brand"
      className="new-brand-modal"
      overlayClassName="new-brand-modal-overlay"
    >
      <h2>Create New Brand</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="sponsor">Sponsor:</label>
          <input
            type="text"
            id="sponsor"
            value={sponsor}
            onChange={(e) => setSponsor(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="brandName">Brand Name:</label>
          <input
            type="text"
            id="brandName"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            required
          />
        </div>
        <div className="form-actions">
          <button type="submit">Create</button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NewBrandModal;