// Header.js
import React, { useContext, useState } from 'react';
import './Header.css';
import { BrandContext } from './BrandContext';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';

const Header = ({ updateBrandOptions }) => {
  const { selectedBrand, setSelectedBrand } = useContext(BrandContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
    setDropdownOpen(false);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
    setConfirmationText('');
  };

  const handleDeleteBrand = async () => {
    if (confirmationText === 'I would like to delete') {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken['https://solsticehealth.co/roles'];

        await fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/delete-brand?teamId=${roles}&brandName=${selectedBrand.label}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setSelectedBrand(null); // Update the context to reflect the deletion
        closeDeleteModal();
        updateBrandOptions(); // Call the function to update brand options
        window.dispatchEvent(new Event('brandDeleted'));

      } catch (error) {
        console.error('Error deleting brand:', error);
      }
    } else {
      alert("Please type 'I would like to delete' to confirm.");
    }
  };

  return (
    <header className="top-header">
      <div className="header-content">
        {selectedBrand && (
          <>
            <span>{selectedBrand.label}</span>
            <div className="dropdown">
              <button onClick={toggleDropdown} className="dropdown-toggle">
                &#9660;
              </button>
              {dropdownOpen && (
                <div className="dropdown-menu">
                  <button onClick={openDeleteModal}>Delete</button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Confirm Delete"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Are you sure you want to delete this brand?</h2>
        <p>All work related to this brand will be deleted. Please type 'I would like to delete' to confirm.</p>
        <input
          type="text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          placeholder="I would like to delete"
        />
        <div className="modal-buttons">
          <button className="delete-button" onClick={handleDeleteBrand}>Delete</button>
          <button className="cancel-button" onClick={closeDeleteModal}>Cancel</button>
        </div>
      </Modal>
    </header>
  );
};

export default Header;
