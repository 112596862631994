// App.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import LoginPage from './LoginPage';
import ClaimsCreation from './ClaimsCreation';
import LoginCallback from './LoginCallback';
import { BrandProvider } from './BrandContext';

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

function AuthenticatedApp() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BrandProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={isAuthenticated ? <Navigate to="/ClaimsCreation" /> : <Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/ClaimsCreation" element={isAuthenticated ? <ClaimsCreation /> : <Navigate to="/login" />} />
          <Route path="/callback" element={<LoginCallback />} />
        </Routes>
      </Router>
    </BrandProvider>
  );
}

function App() {
  return (
    <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <AuthenticatedApp />
    </Auth0Provider>
  );
}

export default App;
