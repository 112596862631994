import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import LogoutButton from './LogoutButton';
import Select from 'react-select';
import { FaPlus, FaUser } from 'react-icons/fa';
import NewBrandModal from './NewBrandModal';
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';

import { BrandContext } from './BrandContext';

const Navbar = ({ updateBrandOptions }) => {
  const { selectedBrand, setSelectedBrand } = useContext(BrandContext);
  const [showNewBrandModal, setShowNewBrandModal] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const fetchBrands = useCallback(async () => {
    if (isAuthenticated) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken['https://solsticehealth.co/roles'];

        const response = await fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/brands?teamId=${roles}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const brands = await response.json();
        const options = brands.map((brand) => ({
          value: brand.id,
          label: brand.brandName,
        }));
        setBrandOptions(options);
        if (updateBrandOptions) updateBrandOptions(options);
        
        // Check if the selected brand still exists in the updated list
        if (selectedBrand && !options.find(option => option.value === selectedBrand.value)) {
          setSelectedBrand(null);
        }
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims, updateBrandOptions, selectedBrand, setSelectedBrand]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  // Add a new useEffect to listen for brand deletion events
  useEffect(() => {
    const handleBrandDeletion = () => {
      fetchBrands();
    };

    window.addEventListener('brandDeleted', handleBrandDeletion);

    return () => {
      window.removeEventListener('brandDeleted', handleBrandDeletion);
    };
  }, [fetchBrands]);

  const handleBrandSelect = (selectedOption) => {
    setSelectedBrand(selectedOption);
  };

  const openNewBrandModal = () => {
    setShowNewBrandModal(true);
  };

  const closeNewBrandModal = () => {
    setShowNewBrandModal(false);
  };

  const handleCreateNewBrand = async (sponsor, brandName) => {
    if (isAuthenticated) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken['https://solsticehealth.co/roles'];

        const response = await fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/brands`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            teamId: roles,
            brandName: brandName,
            sponsor: sponsor,
          }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        fetchBrands();
        closeNewBrandModal();
      } catch (error) {
        console.error('Error creating brand:', error);
      }
    }
  };
  return (
    <nav className="navbar">
      <div className="navbar-header">
        <img src="/solstice.png" alt="Solstice Logo" className="navbar-logo" />
      </div>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/ClaimsCreation" className="nav-link">
            <i className="fas fa-file-alt"></i>
            <span>Create Claims Grid</span>
          </Link>
        </li>
        
        <li className="nav-item brand-select-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={selectedBrand}
              onChange={handleBrandSelect}
              options={brandOptions}
              placeholder="Select a brand"
              className="brand-select"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: '#fff',
                  color: '#333',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: '#333',
                }),
                option: (provided) => ({
                  ...provided,
                  color: '#333',
                }),
              }}
            />
            <button className="add-brand-button" onClick={openNewBrandModal}>
              <FaPlus />
            </button>
          </div>
        </li>
      </ul>
      <div className="navbar-footer">
        <LogoutButton />
      </div>
      <NewBrandModal
        isOpen={showNewBrandModal}
        onClose={closeNewBrandModal}
        onSubmit={handleCreateNewBrand}
      />
    </nav>
  );
};

export default Navbar;
