import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import Navbar from './Navbar';
import Header from './Header';
import { FaTrashAlt, FaThumbsUp, FaThumbsDown, FaHandPaper } from 'react-icons/fa';
import './PreMLRReview.css';
import { BrandContext } from './BrandContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Papa from 'papaparse';

Modal.setAppElement('#root');

const ClaimsCreation = () => {
  const [file, setFile] = useState(null);
  const [reviewData, setReviewData] = useState(null);
  const [previousDocuments, setPreviousDocuments] = useState([]);
  const [latestVersionDates, setLatestVersionDates] = useState({});
  const [clinicalFileModalIsOpen, setClinicalFileModalIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rejectedClaims, setRejectedClaims] = useState([]);
  const documentsPerPage = 10;
  const [rejectionReasons, setRejectionReasons] = useState({});
  const { selectedBrand } = useContext(BrandContext);
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();
  const [taskId, setTaskId] = useState(null);
  const [processingStatus, setProcessingStatus] = useState(null);
  const [rowStatus, setRowStatus] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [thumbsDownModalIsOpen, setThumbsDownModalIsOpen] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [editingIndex, setEditingIndex] = useState(null);
  const [documentComments, setDocumentComments] = useState([]);
  const [documentCommentsModalIsOpen, setDocumentCommentsModalIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [fileError, setFileError] = useState('');
  const [brandError, setBrandError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const fetchPreviousDocuments = useCallback(async () => {
    if (isAuthenticated && selectedBrand) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken['https://solsticehealth.co/roles'];
        const teamId = Array.isArray(roles) ? roles[0] : roles;

        const response = await fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/previous-documents?teamId=${teamId}&brandName=${selectedBrand.label}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setPreviousDocuments(data);
      } catch (error) {
        console.error('Error fetching previous documents:', error);
      }
    }
  }, [isAuthenticated, selectedBrand, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    fetchPreviousDocuments();
  }, [fetchPreviousDocuments]);

  const handleFileChange = async (file) => {
    setFileError('');
    setBrandError('');

    if (!selectedBrand) {
      setBrandError('Please select a brand before uploading a file.');
      return;
    }

    if (file && file.type === 'application/pdf') {
      setFile(file);
      handleFileUpload(file);
    } else {
      setFileError('Please upload a PDF file.');
    }
  };

  const handleThumbsDownConfirm = () => {
    setRejectedClaims((prevRejected) => [
      ...prevRejected,
      {
        ...reviewData[currentRowIndex],
        rejectionReason: rejectionReason,
      },
    ]);

    setReviewData((prevData) => (
      prevData.filter((_, index) => index !== currentRowIndex)
    ));

    setRowStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      delete newStatus[currentRowIndex];
      return newStatus;
    });

    setRejectionReason('');
    setThumbsDownModalIsOpen(false);
  };

  const handleFileUpload = async (file) => {
    if (isAuthenticated) {
      try {
        setIsProcessing(true);
        setProgress(0);
        const formData = new FormData();
        formData.append('clinical_file', file);

        const totalDuration = 5 * 60 * 1000; // 5 minutes
        const updateInterval = 1000; // Update every second

        const updateProgress = () => {
          setProgress((prevProgress) => {
            const newProgress = prevProgress + (30 / (totalDuration / updateInterval));
            return newProgress >= 99 ? 99 : newProgress;
          });
        };

        const progressIntervalId = setInterval(updateProgress, updateInterval);

        const response = await fetch('https://claimsapi-production-v3.up.railway.app/claimsExtraction/request', {
          method: 'POST',
          headers: {
            'X-Token': 'vj=$$R^v~f8gf5E57Wp!`s.4J]oj{uW}%:=g,/,S>#@*&)DJj887CdCsw?R)S,1[0',
          },
          body: formData,
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          console.error('Error response:', errorResponse);
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setTaskId(data.task_uuid);
      } catch (error) {
        console.error('Error uploading file:', error);
        setIsProcessing(false);
      }
    }
  };

  useEffect(() => {
    let intervalId;
    let progressIntervalId;
    const totalDuration = 2 * 60 * 1000; // 2 minutes
    const updateInterval = 1000; // Update every second

    const updateProgress = () => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + (100 / (totalDuration / updateInterval));
        return newProgress >= 99 ? 99 : newProgress;
      });
    };

    const checkTaskStatus = async () => {
      if (taskId) {
        try {
          const response = await fetch(`https://claimsapi-production-v3.up.railway.app/claimsExtraction/results?task_uuid=${taskId}`, {
            method: 'POST',
            headers: {
              'X-Token': 'vj=$$R^v~f8gf5E57Wp!`s.4J]oj{uW}%:=g,/,S>#@*&)DJj887CdCsw?R)S,1[0',
            },
          });
          const data = await response.json();
          console.log(data);

          setProcessingStatus(data.state);

          if (data.task_status === 'Complete') {
            const processedData = data.results.map(item => ({
              statement: item.claim,
              claim: '',
              outcome: '',
              annotationDetails: item.annotationDetails,
              citation: item.citation
            }));

            setReviewData(processedData);

            const initialRowStatus = {};
            processedData.forEach((_, index) => {
              initialRowStatus[index] = 'neutral';
            });
            setRowStatus(initialRowStatus);
            setIsProcessing(false);
            clearInterval(intervalId);
            clearInterval(progressIntervalId);
            setProgress(100); // Set to 100% on completion
          } else if (data.task_status === 'Failure' || data.state === 'UNKNOWN') {
            console.error('Task failed or unknown:', data.error);
            setErrorMessage('Task failed or unknown. Please try again.');
            setIsProcessing(false);
            clearInterval(intervalId);
            clearInterval(progressIntervalId);
            setErrorModalIsOpen(true);
          }
        } catch (error) {
          console.error('Error checking task status:', error);
        }
      }
    };

    if (taskId) {
      intervalId = setInterval(checkTaskStatus, 5000);
      progressIntervalId = setInterval(updateProgress, updateInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (progressIntervalId) {
        clearInterval(progressIntervalId);
      }
    };
  }, [taskId]);

  const handleRowStatus = (index, status) => {
    if (status === 'rejected') {
      setCurrentRowIndex(index);
      setThumbsDownModalIsOpen(true);
    } else {
      setRowStatus((prevRowStatus) => ({
        ...prevRowStatus,
        [index]: status,
      }));
    }
  };

  const handleAddCustomRow = () => {
    const newCustomRow = {
      statement: "",
      claim: "",
      outcome: "",
      annotationDetails: {
        formattedInformation: ""
      },
      custom: true
    };
    setReviewData(prevData => (prevData ? [...prevData, newCustomRow] : [newCustomRow]));
    setRowStatus(prevStatus => ({
      ...prevStatus,
      [reviewData.length]: 'neutral'
    }));
    setEditingIndex(reviewData.length);
  };

  const handleEditRow = (index) => {
    setEditingIndex(index);
  };

  const handleInputChange = (index, field, valueOrEvent) => {
    const value = valueOrEvent && valueOrEvent.target ? valueOrEvent.target.value : valueOrEvent;
    setReviewData(prevData => (
      prevData.map((row, i) =>
        i === index
          ? field.includes('.')
            ? { ...row, [field.split('.')[0]]: { ...row[field.split('.')[0]], [field.split('.')[1]]: value } }
            : { ...row, [field]: value }
          : row
      )
    ));
  };

  const handleSaveRow = (index) => {
    setEditingIndex(null);
  };

  const handleDeleteRow = (index) => {
    setReviewData(prevData => (
      prevData.filter((_, i) => i !== index)
    ));
    setRowStatus(prevStatus => {
      const newStatus = { ...prevStatus };
      delete newStatus[index];
      return newStatus;
    });
  };

  const exportTableToCSV = (tableData, fileName) => {
    const csv = Papa.unparse(tableData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportCurrentTable = () => {
    if (reviewData) {
      const tableData = reviewData.map((claim, index) => ({
        outcome: claim.outcome || '',
        statement: claim.statement || '',
        claim: claim.claim || '',
        annotation: claim.annotationDetails.formattedInformation,
        status: rowStatus[index] || 'neutral',
      }));
      exportTableToCSV(tableData, 'current_review_data.csv');
    }
  };

  const handleExportPreviousTable = (documentName) => {
    if (documentComments && documentComments.length > 0) {
      const tableData = documentComments.map((comment) => ({
        outcome: comment.outcome || '',
        statement: comment.statement || '',
        annotation: comment.annotation || '',
        claim:comment.claim || '',
        status: comment.status || '',
        rejectionReason: comment.rejectionReason || '-'
      }));
      exportTableToCSV(tableData, `${documentName}_comments.csv`);
    } else {
      console.error('No comments available for export');
      // Optionally, you can show an error message to the user here
    }
  };

  const renderTable = () => {
    if (!Array.isArray(reviewData) || reviewData.length === 0) {
      return <p>No review data available.</p>;
    }

    const rows = reviewData.map((claim, index) => (
      <tr key={index} onClick={() => handleEditRow(index)} style={{ cursor: 'pointer' }}>
      <td style={{ width: '10%' }}>
        {editingIndex === index ? (
          <input
            type="text"
            value={claim.outcome || ''}
            onChange={(e) => handleInputChange(index, 'outcome', e)}
            onBlur={() => handleSaveRow(index)}
            style={{ width: '80%', padding: '5px' }}
          />
        ) : (
          <div>{claim.outcome}</div>
        )}
      </td>
        <td style={{ width: '45%' }}>
          {editingIndex === index ? (
            <AutoResizeInput
              initialValue={claim.statement}
              onChange={(value) => handleInputChange(index, 'statement', value)}
              onSave={() => handleSaveRow(index)}
            />
          ) : (
            <div>{claim.statement}</div>
          )}
        </td>
        <td style={{ width: '5%' }}>
          {editingIndex === index ? (
            <AutoResizeInput
              initialValue={claim.annotationDetails.formattedInformation}
              onChange={(value) => handleInputChange(index, 'annotationDetails.formattedInformation', value)}
              onSave={() => handleSaveRow(index)}
            />
          ) : (
            <div>{claim.annotationDetails.formattedInformation}</div>
          )}
        </td>
        <td style={{ width: '35%' }}>
          {editingIndex === index ? (
            <AutoResizeInput
              initialValue={claim.claim}
              onChange={(value) => handleInputChange(index, 'claim', value)}
              onSave={() => handleSaveRow(index)}
            />
          ) : (
            <div>{claim.claim}</div>
          )}
        </td>
        <td className="action-column" style={{ width: '5%' }}>
          <div className="action-buttons" onClick={(e) => e.stopPropagation()}>
            <button
              className={`accept-button ${rowStatus[index] === 'accepted' ? 'active' : ''}`}
              onClick={() => handleRowStatus(index, 'accepted')}
            >
              <FaThumbsUp />
            </button>
            <button
              className="reject-button"
              onClick={() => handleRowStatus(index, 'rejected')}
            >
              <FaThumbsDown />
            </button>
            <button
              className={`neutral-button ${rowStatus[index] === 'neutral' ? 'active' : ''}`}
              onClick={() => handleRowStatus(index, 'neutral')}
            >
              <FaHandPaper />
            </button>
            {claim.custom && (
              <button
                className="delete-row-button"
                onClick={() => handleDeleteRow(index)}
              >
                <FaTrashAlt />
              </button>
            )}
          </div>
        </td>
      </tr>
    ));

    return (
      <div className="table-container">
        <table className="material-table">
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Outcome</th>
              <th style={{ width: '45%' }}>Statement</th>
              <th style={{ width: '5%' }}>Annotation</th>
              <th style={{ width: '35%' }}>Claim</th>
              <th style={{ width: '5%' }}>Action</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <div className="button-container" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '20px' }}>
          <button
            className="add-row-button"
            onClick={handleAddCustomRow}
            style={{ marginRight: '10px' }}
          >
            Add Custom Row
          </button>
          <button
            className="save-button"
            onClick={() => setSaveModalIsOpen(true)}
            style={{ backgroundColor: 'blue' }}
          >
            Save
          </button>
        </div>
      </div>
    );
  };

  const handleDelete = async (document, event) => {
    if (event) {
      event.stopPropagation(); // Prevent row click event
    }
    setDocumentToDelete(document);
    setDeleteModalIsOpen(true);
  };

  const confirmDelete = async () => {
    if (documentToDelete) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken['https://solsticehealth.co/roles'];
        const teamId = Array.isArray(roles) ? roles[0] : roles;

        const response = await fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/delete-comments?documentName=${encodeURIComponent(documentToDelete.document_name)}&teamId=${teamId}&brandName=${encodeURIComponent(selectedBrand.label)}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || `Error: ${response.statusText}`);
        }

        setPreviousDocuments(prevDocs => prevDocs.filter(doc => doc.document_name !== documentToDelete.document_name));

        setDeleteModalIsOpen(false);
        setDocumentToDelete(null);
      } catch (error) {
        console.error('Error deleting comments:', error.message);
      }
    }
  };

  const AutoResizeInput = ({ initialValue, onChange, onSave }) => {
    const [localValue, setLocalValue] = useState(initialValue);
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
      }
    }, [localValue]);

    const handleChange = (e) => {
      setLocalValue(e.target.value);
    };

    const handleBlur = () => {
      onChange(localValue);
      onSave();
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleBlur();
      }
    };

    return (
      <textarea
        ref={inputRef}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        style={{
          width: '100%',
          resize: 'none',
          overflow: 'hidden',
          minHeight: '24px',
          border: '1px solid #ccc',
          background: 'white',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          padding: '5px',
          boxSizing: 'border-box',
        }}
      />
    );
  };

  const handleModalSave = async () => {
    if (documentName && (reviewData.length > 0 || rejectedClaims.length > 0)) {
      try {
        const token = await getAccessTokenSilently();
        const idToken = await getIdTokenClaims();
        const decodedToken = jwtDecode(idToken.__raw);
        const roles = decodedToken['https://solsticehealth.co/roles'];
        const teamId = Array.isArray(roles) ? roles[0] : roles;

        const versionId = new Date().getTime();

        const acceptedComments = reviewData.map((claim, index) => ({
          statement: claim.statement || '',
          claim: claim.claim || '',
          annotation: claim.annotationDetails?.formattedInformation || '',
          citation: claim.citation || '',
          status: rowStatus[index] || 'neutral',
          outcome: claim.outcome || ''
        }));

        const rejectedComments = rejectedClaims.map((claim) => ({
          statement: claim.statement || '',
          claim: claim.claim || '',
          annotation: claim.annotationDetails?.formattedInformation || '',
          citation: claim.citation || '',
          status: 'rejected',
          rejectionReason: claim.rejectionReason || '',
          outcome: claim.outcome || ''
        }));

        const allComments = [...acceptedComments, ...rejectedComments];

        const payload = {
          documentName: documentName,
          comments: allComments,
          userName: user.name,
          teamId: teamId,
          brandName: selectedBrand.label,
          version_id: versionId
        };

        console.log('Payload being sent:', JSON.stringify(payload, null, 2));

        const commentsResponse = await fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/version-comments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });

        if (!commentsResponse.ok) {
          const errorData = await commentsResponse.json();
          throw new Error(errorData.error || `Error: ${commentsResponse.statusText}`);
        }

        const responseData = await commentsResponse.json();
        console.log('Server response:', responseData);
        setSuccessModalIsOpen(true);
        setSaveModalIsOpen(false);
        resetState();
        fetchPreviousDocuments();
        window.location.reload(); // Refresh the page after saving
      } catch (error) {
        console.error('Error saving comments:', error);
      }
    }
  };

  const resetState = () => {
    setFile(null);
    setReviewData([]);
    setRowStatus({});
    setRejectionReasons({});
    setDocumentName('');
    fetchPreviousDocuments();
  };

  const handleDocumentClick = async (document) => {
    if (!document) {
      console.error("Invalid document data:", document);
      return;
    }

    if (selectedDocument && selectedDocument.id === document.id) {
      setDocumentCommentsModalIsOpen(!documentCommentsModalIsOpen);
      return;
    }

    setSelectedDocument(document);
    console.log(document);
    await fetchDocumentComments(document.document_name);
    setDocumentCommentsModalIsOpen(true);
  };

  const fetchDocumentComments = async (documentName) => {
    try {
      const token = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      const decodedToken = jwtDecode(idToken.__raw);
      const roles = decodedToken['https://solsticehealth.co/roles'];
      const teamId = Array.isArray(roles) ? roles[0] : roles;

      const response = await fetch(`${process.env.REACT_APP_BACKEND_APP_API_URL}/api/document-comments/${encodeURIComponent(documentName)}?teamId=${teamId}&brandName=${selectedBrand.label}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Fetched document comments:', data.comments);
      setDocumentComments(data.comments);
    } catch (error) {
      console.error('Error fetching document comments:', error);
    }
  };

  const renderDocumentComments = () => {
    if (!selectedDocument || !documentComments.length) return null;

    return (
      <div className="document-comments-section">
        <div className="document-header" style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}>
          <h3 style={{ margin: 0 }}>{selectedDocument.document_name} Claims</h3>
          <button
            className="action-button export-button"
            onClick={() => handleExportPreviousTable(selectedDocument.document_name)}
            style={{
              backgroundColor: 'green',
              color: 'white'
            }}
          >
            Export as CSV
          </button>
        </div>
        <div className="table-container">
          <table className="material-table">
            <thead>
              <tr>
                <th>Outcome</th>
                <th style={{ width: '40%' }}>Statement</th>
                <th>Annotation</th>
                <th >Claim</th>
                <th style={{ width: '5%' }}>Feedback</th>
              </tr>
            </thead>
            <tbody>
              {documentComments.map((comment, index) => (
                <tr key={index}>
                  <td>{comment.outcome|| '-'}</td>
                  <td>{comment.statement}</td>
                  <td>{comment.annotation}</td>
                  <td>{comment.claim || '-'}</td>
                  <td>
                    <div className="status-indicator">
                      {comment.status === 'accepted' && <FaThumbsUp className="status-icon accepted" />}
                      {comment.status === 'rejected' && <FaThumbsDown className="status-icon rejected" />}
                      {comment.status === 'neutral' && <FaHandPaper className="status-icon neutral" />}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const filteredDocuments = previousDocuments.filter((doc) =>
    doc.document_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const renderProcessingStatus = () => {
    if (isProcessing) {
      return (
        <div className="processing-message">
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}>
              <span className="progress-bar-text">{`${Math.floor(progress)}%`}</span>
            </div>
          </div>
          <p>Processing... Please Wait a Few Moments</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ marginLeft: '200px', paddingTop: '20px' }}>
      <Header />
      <Navbar />
      <div className="container">
        <h2>Create Claims Grid</h2>
        {(!file || clinicalFileModalIsOpen) && (
          <>
            <h3>Which Document Would You Like to Analyze?</h3>
            {brandError && <p className="error-message">{brandError}</p>}
            {fileError && <p className="error-message">{fileError}</p>}
            <div
              className="file-drop"
              onDrop={(event) => {
                event.preventDefault();
                if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                  const file = event.dataTransfer.files[0];
                  handleFileChange(file);
                }
              }}
              onDragOver={(event) => event.preventDefault()}
            >
              <div className="file-drop-content">
                <div className="file-drop-zone">
                  <div className="file-drop-icon">
                    <img src="file-drop-icon.png" alt="File Drop Icon" />
                  </div>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    id="file-input"
                    onChange={(event) => {
                      if (event.target.files && event.target.files.length > 0) {
                        const file = event.target.files[0];
                        handleFileChange(file);
                      }
                    }}
                    accept="application/pdf"
                  />
                  <label htmlFor="file-input" className="file-label">
                    <span className="file-label-text">Please upload content<br />below to review</span>
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        {renderProcessingStatus()}

        {!isProcessing && reviewData && (
          <div className="results-section">
            <h3>Review Results</h3>
            {renderTable()}
          </div>
        )}

        <h2 style={{ marginTop: '30px' }}>Previously Reviewed Documents</h2>
        <div className="table-section">
          <input
            type="text"
            placeholder="Search documents"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ marginBottom: '20px', padding: '10px', width: '300px' }}
          />
          <table className="material-table">
            <thead>
              <tr>
                <th>Claims Name</th>
                <th>Date Created</th>
                <th>Created By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentDocuments.map((doc, index) => (
                <tr
                  key={index}
                  onClick={() => handleDocumentClick(doc)}
                  className={selectedDocument && selectedDocument.id === doc.id ? 'selected-row' : ''}
                >
                  <td>{doc.document_name}</td>
                  <td>{new Date(doc.uploaded_at).toLocaleDateString()}</td>
                  <td>{doc.user_id}</td>
                  <td>
                    <div className="action-buttons">
                      <button
                        className="delete-button"
                        onClick={(e) => handleDelete(doc, e)}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: Math.ceil(filteredDocuments.length / documentsPerPage) }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`page-number ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>

        {documentCommentsModalIsOpen && renderDocumentComments()}
      </div>
      <Modal
        isOpen={saveModalIsOpen}
        onRequestClose={() => setSaveModalIsOpen(false)}
        contentLabel="Save Document"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Save Document</h2>
        <input
          type="text"
          value={documentName}
          onChange={(e) => setDocumentName(e.target.value)}
          placeholder="Enter document name"
        />
        <div className="modal-buttons">
          <button className="save-button" onClick={handleModalSave}>
            Save
          </button>
          <button className="cancel-button" onClick={() => setSaveModalIsOpen(false)}>
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={thumbsDownModalIsOpen}
        onRequestClose={() => setThumbsDownModalIsOpen(false)}
        contentLabel="Rejection Reason"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Rejection Reason</h2>
        <textarea
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
          placeholder="What was the problem with this suggestion?"
          rows="4"
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '20px',
            fontSize: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
        <div className="modal-buttons">
          <button className="save-button" onClick={handleThumbsDownConfirm}>
            Confirm
          </button>
          <button className="cancel-button" onClick={() => setThumbsDownModalIsOpen(false)}>
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        contentLabel="Confirm Delete"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this document?</p>
        <div className="modal-buttons">
          <button className="delete-button" onClick={confirmDelete}>
            Delete
          </button>
          <button className="cancel-button" onClick={() => setDeleteModalIsOpen(false)}>
            Cancel
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={successModalIsOpen}
        onRequestClose={() => setSuccessModalIsOpen(false)}
        contentLabel="Success"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Success</h2>
        <p>Document and comments saved successfully!</p>
        <div className="modal-buttons">
          <button className="save-button" onClick={() => setSuccessModalIsOpen(false)}>
            OK
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={errorModalIsOpen}
        onRequestClose={() => setErrorModalIsOpen(false)}
        contentLabel="Error"
        className="new-brand-modal"
        overlayClassName="new-brand-modal-overlay"
      >
        <h2>Error</h2>
        <p>{errorMessage}</p>
        <div className="modal-buttons">
          <button className="refresh-button" onClick={() => window.location.reload()}>
            Refresh
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ClaimsCreation;
